.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .wrapper__breadcrumb {
    margin: 0 auto;
    padding: 16px 44px;
    display: flex;
    font-size: 18px;

    .breadcrumb__item {
      display: flex;
      align-items: center;

      &::before {
        margin-right: 8px;
        margin-left: 8px;
        display: block;
        font-size: 20px;
        content: '/';
      }

      &:first-child::before {
        display: none;
      }
    }
  }

  .layout-alerts {
    margin-top: 16px;
    padding: 16px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .layout-alert {
      // max-width: 450px;
      // min-width: 300px;
      width: 100%;
      min-height: 40px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background-color: $primary;
      color: black;
      font-weight: bold;
      overflow: hidden;

      &:first-child {
        margin-top: 0;
      }

      .alert__message {
        padding: 8px 16px;
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .alert__close {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        line-height: 15px;
        cursor: pointer;

        &:hover {
          background-color: darken($primary, 15%);
        }
      }
    }
  }

  .layout-header-menu {
    max-width: 300px;

    .MuiMenuItem-root {
      .handle-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .layout-bg {
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: -1;
    background-image: url('../images/back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.4;
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
      width: 100%;
      flex-grow: 1;
    }
  }

  header {
    .form-select {
      option {
        color: black;
      }
    }

    .header-top-menu {
      .nav-link {
        align-items: center;
      }
    }

    .avatar-image {
      img {
        border-radius: 50%;
        background-color: black;
      }
    }
  }
}

.header-top-menu {
  .MuiMenuItem-root {
    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;

      .nav-link {
        pointer-events: none;
      }
    }
  }

  .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .menu-item__soon-label {
      bottom: 0;
      position: absolute;
      font-size: 9px;
    }
  }
}

.update-ngo-list-item {
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.drawer-main-navigation {
  .MuiPaper-root {
    max-width: 200px;
  }

  .MuiMenuItem-root {
    .handle-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media (max-width: 768px) {
  .layout-wrapper {
    header {
      .header-user-item {
        pointer-events: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .layout-wrapper {
    .wrapper__breadcrumb {
      flex-direction: column;
      line-height: 14px;
      font-size: 12px;

      .breadcrumb__item {
        margin: 2px 0;

        &::before {
          color: white;
          font-weight: 900;
          line-height: 15px;
          font-family: 'Font Awesome 5 Free';
          font-size: 14px;
          content: '\f061';
        }

        &:nth-child(2) {
          margin-left: 6px;
        }

        &:nth-child(3) {
          margin-left: 12px;
        }

        &:nth-child(4) {
          margin-left: 18px;
        }

        &:nth-child(5) {
          margin-left: 24px;
        }

        &:nth-child(6) {
          margin-left: 30px;
        }

        &:nth-child(7) {
          margin-left: 36px;
        }
      }
    }
  }
}
