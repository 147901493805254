.phone-input {
  width: 100%;
  display: flex;
  align-items: center;
  color: white;

  .MuiAvatar-root {
    height: 28px;
    width: 28px;
    margin-left: 16px;
    cursor: pointer;
  }

  .MuiInput-input {
    margin-right: 16px;
  }
}

@media (min-width: 600px) {
  .phone-input__modal {
    .MuiContainer-root {
      max-width: 400px;
    }
  }
}
