.swiper-wrapper {
  margin-bottom: 0;
}

.swiper-slide {
  height: auto;
}

.swiper-pagination-fraction, .swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-pagination-bullet {
  background: $primary;
  opacity: var(--swiper-pagination-bullet-opacity, .5);
}

.swiper-pagination-bullet-active {
  background: $primary;
  opacity: var(--swiper-pagination-bullet-opacity, 1);
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next,
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  color: $primary
}
