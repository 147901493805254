::placeholder {
  color: $placeholder-color;
  opacity: 0.8;
}

:-ms-input-placeholder {
  color: $placeholder-color;
}

::-ms-input-placeholder {
  color: $placeholder-color;
}

.loading-overlay {
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);

  &.hidden {
    display: none;
  }
}

.MuiFormControl-root {
  margin-bottom: 1.5rem;

  &.text-center {
    input {
      text-align: center;
    }
  }

  &.text-left {
    input {
      text-align: left;
    }
  }

  &.text-right {
    input {
      text-align: right;
    }
  }

  + .MuiFormControl-root {
    margin-left: 24px;
  }

  .MuiInputLabel-standard {
    &.MuiInputLabel-root {
      top: -10px;
      color: $placeholder-color;
      opacity: 0.3;

      &.MuiInputLabel-shrink {
        opacity: 1;
      }
    }
  }
}

.MuiInput-root {
  input {
    padding-right: 12px;
    padding-left: 12px;
    border: none;
    background: transparent;
    color: white;
    @media (min-width: 992px) {
      padding-right: 12px;
      padding-left: 12px;
      letter-spacing: 3px;
    }
  }

  &.text-area,
  &.image-crop-input {
    .MuiInputLabel-standard.MuiInputLabel-root {
      opacity: 1;
    }
  }

  &.MuiInputBase-colorPrimary {
    &:before {
      border-bottom-color: $input-border-color-primary;
    }

    &:after {
      border-bottom-color: $primary;
    }

    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border-bottom-color: $primary;
        }
      }

      .MuiInputAdornment-root {
        .MuiIconButton-root {
          color: $primary;
        }
      }
    }

    .MuiInputAdornment-root {
      .MuiIconButton-root {
        color: white;
      }
    }
  }

  &.MuiInputBase-colorSecondary {
    &:before {
      border-bottom-color: $input-border-color-secondary;
    }

    &:after {
      border-bottom-color: $secondary;
    }

    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border-bottom-color: $secondary;
        }
      }
    }
  }
}

.text-area,
.image-crop-input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    text-align: left;
  }

  textarea {
    padding-right: 12px;
    padding-left: 12px;
    border: none;
    border-bottom: solid 2px $input-border-color-primary;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    letter-spacing: 3px;

    &:hover,
    &:focus,
    &:focus-visible {
      border-bottom-color: $primary;
      outline: none;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:read-only {
  color: #6b747c;
}
