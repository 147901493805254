.fundraiser-list {
  display: flex;
  flex-direction: column;

  > a {
    margin-bottom: 32px;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fundraiser {
    width: 100%;
    flex-shrink: 0;
  }

  .list-empty {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    border-radius: 8px;

    .list-empty__icon {
      height: 48px;
      width: 58px;
      margin-right: 16px;
      background-image: url(../../images/emptyFolder.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .list-empty__label {
      color: $primary;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
