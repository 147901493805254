.DashboardSidebar {
  flex: 25% 1 1;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;

  @import 'advertisement';
  @import "calendar";
}

