.performance-calendar {
  .modeWrapper {
    display: none;
  }

  .calendarHeader {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 1rem;
      color: white;
      font-weight: bold;
      padding-bottom: 16px;
    }

    .thickText, .thinText {
      font-weight: normal;
      text-transform: uppercase;
    }

    + div {
      margin-bottom: 16px;
    }
  }

  .calendarHeaderButtons {
    position: absolute;
    left: 0;
    right: 0;
    color: $primary
  }

  .dayCell {
    height: auto;
    .inactiveDay {
      opacity: .35;
    }
  }

  .dayText{
    color: $light
  }

  .currentDay {
    background-color: $secondary;
    color: $dark
  }
}


