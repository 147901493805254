.JoinNGO-window {
  width: 100%;
  max-width: calc(420px + 15vw);
  margin: auto;
  padding: 1rem;
  border: 2px solid var(--bs-orange);
  border-radius: 8px;
  background-color: #000000a3;
  
  .form-select {
    border: 1px solid var(--bs-orange);
    background: transparent;
    color: #fff;
  }

  .JoinNGO__list {
    button {
      max-width: 90%;
      margin: 4px auto;
      border: 1px solid var(--bs-orange);    
      
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
  }

  .JoinNGO_btn-clear {
    right: 9px;
    top: 5px;
    position: absolute;
  }

  .JoinNGO-image {
    width: 50px !important;
  }

  .JoinNGO-name {
    width: 70%;
    max-width: 300px;
    color: var(--bs-orange) !important;
    text-align: left;
    
  }

  .JoinNGO-status {
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .JoinNGO-end {
    width: 150px;
    padding: 1px 8px;
    border: 1px solid;
    color: var(--bs-orange);
    text-align: center;

    @media (max-width: 991px) {
      width: 200px;
      margin: auto;
    }

  }
}
