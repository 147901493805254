.address-lookup {
  margin-bottom: 24px;
  display: flex;

  &.lookup--model-manual {
    padding: 16px;
    flex-direction: column;

    .btn {
      align-self: flex-end;
    }
  }

  .address-lookup__search-input {
    margin-right: 8px;
    flex-grow: 1;
    cursor: text;

    .react-select__control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid white;
      background: transparent;

      &.react-select__control--is-focused,
      &:hover {
        border: none;
        border-bottom: 2px solid $primary;
        box-shadow: none;
      }
    }

    .react-select__placeholder {
      color: transparentize(#fff, 0.7);
      text-align: left;
    }

    .react-select__value-container,
    .react-select__input-container {
      color: white;
      cursor: text;
    }

    .react-select__input {
      letter-spacing: 0 !important;
    }

    .react-select__single-value {
      color: white;
      text-align: left;
    }

    .react-select__indicators {
      cursor: pointer;
    }

    .react-select__option {
      color: transparentize(black, 0.4);
      text-align: left;

      &:active,
      &.react-select__option--is-focused,
      &:hover {
        background-color: transparentize($primary, 0.8);
      }
    }
  }

  // .address-lookup__search-input {
  //   width: 100%;

  //   &.input--autocomplete {
  //     .react-select__control {
  //       border: 1px solid #e2e4e5;
  //       box-shadow: none;

  //       &:hover {
  //         border-color: #e2e4e5 !important;
  //       }
  //     }

  //     .react-select__value-container {
  //       padding: 2px 8px;
  //     }

  //     .react-select__placeholder {
  //       margin-left: 2px;
  //       margin-right: 2px;
  //     }

  //     .react-select__input-container {
  //       margin: 2px;
  //       padding-bottom: 2px;
  //       padding-top: 2px;
  //     }

  //     .react-select__single-value {
  //       margin-left: 2px;
  //       margin-right: 2px;
  //     }

  //     .react-select__indicator-separator {
  //       margin-bottom: 8px;
  //       margin-top: 8px;
  //     }

  //     .react-select__indicator {
  //       padding: 8px;

  //       span {
  //         font-size: 4px;

  //         &:not(:first-child) {
  //           margin-left: 1em;
  //         }
  //       }
  //     }

  //     .react-select__menu {
  //       margin-bottom: 8px;
  //       margin-top: 8px;

  //       .react-select__menu-list {
  //         padding-bottom: 4px;
  //         padding-top: 4px;
  //       }

  //       .react-select__option {
  //         padding: 8px 12px;
  //       }
  //     }

  //     input {
  //       min-height: 30px;

  //       &:focus {
  //         box-shadow: none;
  //       }
  //     }
  //   }
  // }
}
