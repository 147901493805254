.swiper {
  width: calc(100% - 50px);
  padding-right: 0;
  padding-left: 0;
  @import 'swiper';
}

.swiper-container {
  position: relative;

  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -10px;
  }

  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -10px;
  }

  .swiper-button-prev, .swiper-rtl .swiper-button-next,
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    width: 24px;
    color: $primary;
    overflow: hidden;

    &:after {
      display: none;
    }
  }
}
