.modal-notes {
    .modal-content {
        background-color: #000;

        .btn-close {
            margin-left: -10px;
            background-color: var(--bs-orange);
        }
    
        .modal-body {
            background-color: #000;
    
            h4, h3, p {
                color: #fff;
            }
    
            .notes-window {
                width: 100%;
                min-height: 70vh;
                max-width: 648px;
                position: relative;
                border: 2px solid var(--bs-orange);
                border-radius: 1rem;
                background-color: #000;
            
                @media (max-width: 991px) {
                    max-width: 100%;
                }
            
                .notes-header {
                    height: 57px;
            
                    @media (max-width: 991px) {
                        display: none !important;
                    }
                }
            
                .notes-body {
                    max-height: 250px;
                    padding: 10px;
                    overflow: auto;
                }
            
                .notes-item {
                    padding: 5px;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid var(--bs-orange);
                    border-radius: 7px;
                    background-color: #fd7e140f;
            
                    p {
                        width: 95%;
                        margin: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
            
                    @media (max-width: 991px) {
                        .w-50 {
                            width: 100% !important;
                        }
            
                        .w-25 {
                            width: 100% !important;
                        }
                    }
                }
            
                .notes-actions {
                    width: 92% !important;
                    bottom: 52px;
                    position: absolute;
                    text-align: end;
            
                    &.notes-actions--form {
                        text-align: start;
                    }
            
                    .btn-notes__add {
                        height: 62px;
                        width: 62px;
                        padding: 0;
                        border-radius: 3rem;
                    }
            
                    .btn-notes__back {
                        left: 0;
                        bottom: 6px;
                        position: absolute;
                    }
                }
            
                .notes-actions__btn-save {
                    right: 28px;
                    bottom: 56px;
                    position: absolute;
                    z-index: 99;
                }
            }
        
            .MuiFormControl-root {
                width: 100%;
    
                label {
                    color: #fff;
                }
        
                input {
                    height: 1.4375em;
                    width: 100%;
                    min-width: 0;
                    margin: 0;
                    padding: 4px 0 5px;
                    display: block;
                    box-sizing: content-box;
                    border: none;
                    border-bottom: 2px solid #fff;
                    background: none;
                    color: #fff;
                    letter-spacing: inherit;
                    -webkit-tap-highlight-color: transparent;
                    -webkit-animation-name: mui-auto-fill-cancel;
                    animation-name: mui-auto-fill-cancel;
                    -webkit-animation-duration: 10ms;
                    animation-duration: 10ms;
                }
        
                textarea {
                    height: 1.4375em;
                    width: 100%;
                    min-width: 0;
                    margin: 0;
                    padding: 4px 0 5px;
                    display: block;
                    box-sizing: content-box;
                    border: none;
                    border-bottom: 2px solid #fff;
                    background: none;
                    color: #fff;
                    letter-spacing: inherit;
                    -webkit-tap-highlight-color: transparent;
                    -webkit-animation-name: mui-auto-fill-cancel;
                    animation-name: mui-auto-fill-cancel;
                    -webkit-animation-duration: 10ms;
                    animation-duration: 10ms;
                }
        
            }
        }
    }
}

.modal-open {
    overflow: hidden;
}