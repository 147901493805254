.notes-index {
    align-items: flex-start;
    flex-grow: 0 !important;
    
    .notes-window {
        width: 100%;
        min-height: 485px;
        max-width: 648px;
        position: relative;
        border: 2px solid var(--bs-orange);
        border-radius: 1rem;
        background-color: #2e2e2e91;
    
        @media (max-width: 991px) {
            max-width: 100%;
        }
    
        .notes-header {
            height: 57px;
    
            @media (max-width: 991px) {
                display: none !important;
            }
        }
    
        .notes-body {
            max-height: 250px;
            padding: 10px;
            overflow: auto;
        }
    
        .notes-item {
            padding: 5px;
            justify-content: center;
            align-items: center;
            border: 2px solid var(--bs-orange);
            border-radius: 7px;
            background-color: #fd7e140f;
    
            p {
                width: 95%;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
            @media (max-width: 991px) {
                .w-50 {
                    width: 100% !important;
                }
    
                .w-25 {
                    width: 100% !important;
                }
            }
        }
    
        .notes-actions {
            width: 92% !important;
            bottom: 52px;
            position: absolute;
            text-align: end;
    
            &.notes-actions--form {
                text-align: start;
            }
    
            .btn-notes__add {
                height: 62px;
                width: 62px;
                padding: 0;
                border-radius: 3rem;
            }
    
            .btn-notes__back {
                left: 0;
                bottom: 6px;
                position: absolute;
            }
        }
    
        .notes-actions__btn-save {
            right: 28px;
            bottom: 56px;
            position: absolute;
            z-index: 99;
        }
    }
}