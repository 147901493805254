.participant-list {
  display: flex;
  justify-content: center;
  align-items: center;

  .add-participant {
    height: 48px;
    width: 48px;
    margin-left: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: $primary;
    color: black;
    font-weight: bold;
    line-height: 30px;
    font-size: 40px;
  }

  .list__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .participant {
    margin-right: 0;
    transition: all 0.5s ease;

    &:not(:first-child) {
      margin-left: -24px;

      &.participant--hovered {
        margin-left: 8px;
      }
    }

    &.participant--hovered + .participant {
      margin-left: 8px;
    }
  }
}

.participant-modal {
  .modal-body {
    overflow: unset;
  }

  .participant-search-container {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 32px;
    }
  }
}
