.advertisement{
  border-radius: 25px;
  border: solid medium $primary;
  position: relative;
  overflow: hidden;
  .advertisement-text{
    padding-left: 12px;
    padding-right: 12px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }
}
