.MuiAvatar-root {
  &.MuiAvatar-circular {
    height: 64px;
    width: 64px;
    padding: 4px;
    overflow: visible;

    &.border {
      border: solid 2px #ff8830 !important;
    }

    img {
      border-radius: 50%;
      background: white;
      object-fit: contain;
    }
  }
}


.switch-user-menu {
  .MuiAvatar-root {
    &.MuiAvatar-circular {
      height: 48px;
      width: 48px;
      margin: 0;
    }
  }
  .MuiPopover-paper ul li{
    padding: 16px 24px 16px 16px;
  }
}

