.Dashboard {
  .section {
    margin-top: 48px;
  }

  .section-title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: left;
    font-size: 1.25em;
  }

  .tips-item {
    svg {
      color: $primary;
      font-size: 72px;
    }

    .tips-label {
      font-size: 1.5rem;
    }

    .tips-value {
      font-size: 3rem;
      font-weight: 100;
      margin-top: 0;
    }
  }


  .clients-container {
    display: flex;
    flex-wrap: wrap;
  }

  .clients-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;

    .MuiAvatar-root {
      margin: 8px auto;
      width: 64px;
      height: 64px;
      border: solid 2px #ff8830;
      overflow: visible;
      padding: 4px;

      img {
        object-fit: contain;
        border: solid 4px black;
        border-radius: 50%;
        background: white;
      }
    }
  }

  .client-percentage {
    font-size: $h3;
    font-weight: 100;
    margin-block-end: 8px;
    margin-block-start: 8px;
  }

}
