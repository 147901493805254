.participant {
  height: 48px;
  width: 48px;
  display: flex;
  border: 2px solid $primary;
  border-radius: 50%;
  background-image: url('../../images/campaignDefault.png');
  background-size: cover;
  background-position: 50%;
}
