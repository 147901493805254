.no-border {
  border: none !important;
}

.border {
  border-top: solid thin $primary !important;
}

.border-top {
  border-top: solid thin $primary !important;
}

.border-bottom {
  border-bottom: solid thin $primary !important;
}

.border-left {
  border-left: solid thin $primary !important;
}

.border-right {
  border-right: solid thin $primary !important;
}


$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;

$border-width: 1px;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
);

$border-color: $primary;
