//Colors
$primary: #FF9900;
$primary-disabled: rgba(255, 153, 0, 0.77);
$secondary: #00ff00;
$light: #fff;
$light-opacity: rgba(255,255,255,.75);
$dark: #000;
$dark-opacity: rgba(0,0,0,.75);

$border-color-dark: #323232;

$link: $primary;


//Backgrounds
$bg-light: $light;
$bg-dark: $dark;

//Text Colors
$text-color-light: $light;
$text-color-dark: $dark;

//Inputs
$input-border-color-primary: #fff;
$input-border-color-secondary: #fff;
$placeholder-color: #fff;

