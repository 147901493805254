@import 'globals/index';
.pointer {
  cursor: pointer;
}
.divider {
  height: 0;
  width: 100%;
  margin-bottom: 0rem;
  margin-top: 2rem;
  display: block;
  border-bottom: solid 2px $light;

  &.primary {
    border-color: $primary;
  }

  &.secondary {
    border-color: $secondary;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: none !important;
  background-color: transparent !important;
  background-clip: content-box !important;
}

.overflow-menu {
  max-height: 269px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid var(--bs-orange);
    border-radius: 20px;
    background-color: var(--bs-orange);
  }

  @media (max-width: 1399px) {
    display: contents;
  }

}

.banner__progress-overlay {
  width: 85%;
  margin: auto;
  position: static;
}

.avatar__progress-overlay {
  margin-left: -17px;
  margin-top: 11px;
  position: absolute;
}