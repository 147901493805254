//Display Types
.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.v-hidden {
  visibility: hidden;
}