.Login {
  min-height: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .background {
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    object-fit: cover;
  }

  .country-code {
    @media (min-width: 992px) {
      max-width: 110px;
    }

    .MuiAvatar-root {
      height: 32px;
      width: 32px;
      top: -5px;
    }
  }

  .MuiInput-underline .input {
    &:before {
      border-bottom-color: pink;
    }
  }

  .MuiCheckbox-root:not(.Mui-checked) {
    path {
      fill: $primary;
    }
  }

  .terms-conditions__container {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 600px) {
  .Login {
    .MuiBox-root {
      width: 400px;
      margin: 0 auto;
    }
  }

  .VerifySms {
    .MuiBox-root {
      width: 450px;
      margin: 0 auto;
    }
  }
}
