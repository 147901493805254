.fundraiser-view {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .view__container {
    display: flex;
    flex-direction: column;

    &.container--left {
      margin-right: 16px;
      padding: 16px 32px;
      flex: auto;
      border-radius: 8px;
      background-color: transparentize(black, 0.3);
    }

    &.container--right {
      margin-left: 16px;
      flex: auto;
    }

    &.bg--black {
      padding: 32px;
      border-radius: 8px;
      background-color: transparentize(black, 0.3);
    }
  }

  .event__title {
    margin-bottom: 8px;
    display: flex;
    color: $primary;
    font-size: 38px;
  }

  .event__sub-title {
    margin-bottom: 8px;
    display: flex;
    color: $primary;
    font-size: 28px;
  }

  .event__header {
    display: flex;
    flex-direction: column;

    .header__banner {
      height: 300px;
      width: 100%;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
    }

    .header__banner-details {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
    }
  }

  .event__avatar {
    height: 100px;
    width: 100px;
    margin-top: -32px;
    border: 2px solid $primary;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  .event__participants {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
  }

  .event__address {
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    i {
      color: $primary;
      font-size: 32px;
    }

    .address__full-address {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .full-address__city {
        font-weight: bold;
      }
    }
  }

  .event__body {
    p {
      text-align: justify;
    }
  }

  .event__admins {
    display: flex;
    flex-direction: column;
    border-top: 1px solid white;

    .admins__admin {
      margin-top: 8px;
      display: flex;
    }

    .admin__avatar {
      height: 48px;
      width: 48px;
      // border: 2px solid $primary;
      border-radius: 50%;
      background-image: url('../../images/avatarDefault.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
    }

    .admin__details {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
    }

    .admin__username {
      display: flex;
      justify-content: flex-start;
      color: $primary;
    }

    .admin__location {
      display: flex;
      justify-content: flex-start;
    }
  }

  .event__goal {
    display: flex;
    flex-direction: column;

    .goal__text {
      display: flex;
      align-items: center;

      div {
        margin-right: 4px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .goal__progress {
      border: 2px solid white;
      border-radius: 8px;

      .progress__bar {
        height: 12px;
        border-radius: 7px;
        background-color: $primary;
      }
    }

    .goal__total-donations {
      display: flex;
      justify-content: flex-end;
    }
  }

  .event__actions {
    margin-top: 16px;

    .btn {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .event__time {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .time__item {
      width: 100%;
      max-width: 80%;
      margin-bottom: 16px;
      padding: 4px;
      display: flex;
      flex-grow: 1;
      border-radius: 4px;
      background-color: $primary;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .time__type,
    .time__date,
    .time__time {
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      color: black;
      font-weight: bold;
      line-height: 26px;
      font-size: 24px;
    }

    .time__date,
    .time__time {
      border-left: 3px solid black;
    }
  }
}

@media (max-width: 767px) {
  .campaign-view {
    padding: 32px 16px;
    flex-direction: column;

    > .view__container {
      margin: 0;

      &.container--left,
      &.container--right {
        margin: 0;
      }

      &.container--right {
        margin-top: 32px;
      }
    }
  }
}
