.fundraiser-filter__item {
  height: 30px;
  margin: 4px;
  display: flex;
  border: 2px solid $primary;
  border-radius: 8px;
  line-height: 14px;
  font-size: 14px;

  .item__value {
    margin-right: 8px;
    padding: 2px 4px;
    display: flex;
    align-items: center;

    > div {
      margin-right: 8px;
    }

    > div:first-child {
      margin-right: 4px;
    }
  }

  .item__btn {
    height: 100%;
    padding: 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $primary;
    color: white;
    font-weight: bold;
    font-weight: bold;
    line-height: 14px;
  }
}
