.MuiModal-root .MuiContainer-root {
  width: 80%;
  max-height: 80%;
  left: 50%;
  top: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.modal-header {
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: solid thin #e4e4e4;

  .title {
    flex: auto;
  }
}
.modal-body{
  margin-bottom: 16px;
  padding: 16px;
  overflow: auto;
}

