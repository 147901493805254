html,
body,
#root,
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  min-width: 350px;
  background: black;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

span {
  display: inline-block;
}

.App {
  color: white;
}
