//Text Colors
$h1: 3.2em;
$h2: 2.8em;
$h3: 1.75em;
$h4: 1.5em;
$h5: 1.25em;
$h6: 1em;

h1, .h1 {
  display: block;
  font-weight: bold;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h2, .h2 {
  display: block;
  font-weight: bold;
  font-size: $h2;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h3, .h3 {
  display: block;
  font-weight: bold;
  font-size: $h3;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h4, .h4 {
  display: block;
  font-weight: bold;
  font-size: $h4;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h5, .h5 {
  display: block;
  font-weight: bold;
  font-size: $h5;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h6, .h6 {
  display: block;
  font-weight: bold;
  font-size: $h6;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

//Text Aligns
.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

//Links
.text-link {
  color: $link;
}
