.Welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    object-fit: cover;
    opacity: .4;
  }

  .country-code {
    @media(min-width: 992px) {
      max-width: 110px;
    }

    .MuiAvatar-root {
      width: 32px;
      height: 32px;
      top: -5px;
    }
  }

  .MuiInput-underline .input {
    &:before {
      border-bottom-color: pink;
    }
  }

  .MuiListItem-root {
    align-items: center;
    justify-content: center;
  }

  .welcome-logo {
    width: 80%;
    height: auto;
    max-width: 350px;
  }

  .welcome-logo-influencers {
    width: 50%;
    height: auto;
    max-width: 200px;
  }
}
